import clsx from 'clsx';
import PropTypes from 'prop-types';


const variants = {
  primary: { logoColor: '#2563EB', textColor: '#0F172A' },
  white: { logoColor: '#FFFFFF', textColor: '#FFFFFF' },
};

export function Logo({ variant = 'primary', className, ...props }) {
  const colors = variants[variant] || variants.primary;

  return (
    <div className={clsx('flex items-center', className)} {...props}>
      <svg aria-hidden="true" viewBox="0 0 109 40" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 20c0 11.046 8.954 20 20 20s20-8.954 20-20S31.046 0 20 0 0 8.954 0 20Zm20 16c-7.264 0-13.321-5.163-14.704-12.02C4.97 22.358 6.343 21 8 21h24c1.657 0 3.031 1.357 2.704 2.98C33.32 30.838 27.264 36 20 36Z"
          fill={colors.logoColor}
        />
      </svg>
      <span className={clsx('ml-2 text-lg font-bold', colors.textColor)}>Waktai</span>
    </div>
  );
}

Logo.propTypes = {
  variant: PropTypes.oneOf(['primary', 'white']),
  className: PropTypes.string,
};
