import { MessageCircle } from 'lucide-react';
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Container } from '../ui/Container';

const navigation = {
  solutions: [
    { name: 'AI Operations', href: '/solutions/ai-ops' },
    { name: 'Workflow Automation', href: '/solutions/workflow' },
    { name: 'Analytics & Insights', href: '/solutions/analytics' },
    { name: 'Enterprise', href: '/enterprise' },
  ],
  support: [
    { name: 'Documentation', href: '/docs' },
    { name: 'API Reference', href: '/docs/api' },
    { name: 'Status', href: '/status' },
    { name: 'Release Notes', href: '/releases' },
  ],
  company: [
    { name: 'About', href: '/about' },
    { name: 'Blog', href: '/blog' },
    { name: 'Careers', href: '/careers' },
    { name: 'Press', href: '/press' },
    { name: 'Partners', href: '/partners' },
  ],
  legal: [
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' },
    { name: 'Security', href: '/security' },
    { name: 'Compliance', href: '/compliance' },
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/waktai',
      icon: FaTwitter,
    },
    {
      name: 'LinkedIn',
      href: 'https://linkedin.com/company/waktai',
      icon: FaLinkedin,
    },
    {
      name: 'GitHub',
      href: 'https://github.com/waktai',
      icon: FaGithub,
    },
    {
      name: 'Discord',
      href: 'https://discord.gg/waktai',
      icon: MessageCircle,
    },
  ],
};

export function Footer() {
  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <Container>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <Link to="/" aria-label="Go to Waktai homepage">
                <span className="sr-only">Waktai</span>
                <div className="text-2xl font-bold text-white">Waktai</div>
              </Link>
              <p className="text-sm leading-6 text-gray-300">
                Enterprise AI Operations Platform
                <br />
                Transforming businesses with intelligent automation
              </p>
              <div className="flex space-x-6">
                {navigation.social.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-gray-400 hover:text-gray-300"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Follow us on ${item.name}`}
                  >
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Solutions
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {navigation.solutions.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                          aria-label={`Learn more about ${item.name}`}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Support
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                          aria-label={`Access our ${item.name}`}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Company
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                          aria-label={`Learn more about ${item.name}`}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Legal
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                          aria-label={`View our ${item.name} information`}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
            <div className="flex flex-col items-center justify-between gap-y-4 md:flex-row">
              <p className="text-xs leading-5 text-gray-400">
                &copy; {new Date().getFullYear()} Waktai, Inc. All rights
                reserved.
              </p>
              <div className="flex space-x-6 text-sm font-semibold leading-6 text-gray-400">
                <span>Made with ❤️ globally</span>
                <span>&middot;</span>
                <Link to="/terms" className="hover:text-white">
                  Terms
                </Link>
                <span>&middot;</span>
                <Link to="/privacy" className="hover:text-white">
                  Privacy
                </Link>
                <span>&middot;</span>
                <Link to="/cookies" className="hover:text-white">
                  Cookies
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
  