export const seoConfig = {
  siteName: process.env.REACT_APP_SITE_NAME || "WaktAI",
  siteUrl: process.env.REACT_APP_SITE_URL || "https://waktai.com",
  organization: {
    name: process.env.REACT_APP_ORGANIZATION_NAME || "WaktAI",
    logoUrl: process.env.REACT_APP_ORGANIZATION_LOGO || "https://waktai.com/logo.jpg",
    foundingDate: "2024",
    contactPoint: {
      email: process.env.REACT_APP_CONTACT_EMAIL || "ai@waktai.com",
      telephone: process.env.REACT_APP_CONTACT_PHONE || "+1-XXX-XXX-XXXX",
      contactType: "customer service",
    },
    socialLinks: [
      "https://www.facebook.com/WaktaiAI",
      "https://twitter.com/WaktaiAI",
      "https://www.linkedin.com/company/waktai",
    ],
  },
  defaults: {
    title: "WaktAI - Empowering SMBs and Enterprises leverage Gen AI for Business Productivity",
    description:
      "Transform your enterprise operations with AI-powered solutions. Streamline workflows, enhance productivity, and drive sustainability with WaktAI.",
    ogImage: process.env.REACT_APP_OG_IMAGE || "https://waktai.com/og-image.jpg",
    keywords: [
      "AI Solutions",
      "Enterprise AI",
      "Sustainability",
      "Business Automation",
      "AI Operations",
      "Enterprise Software",
      "Generative AI",
      "Large Language Models",
      "Retrieval-Augmented Generation",
      "Agentic AI",
      "AI for SMBs",
    ].join(", "),
  },
  getSchemas: {
    website: () => ({
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: process.env.REACT_APP_SITE_NAME || "WaktAI",
      url: process.env.REACT_APP_SITE_URL || "https://waktai.com",
      potentialAction: {
        "@type": "SearchAction",
        target: `${process.env.REACT_APP_SITE_URL}/search?q={search_term_string}`,
        "query-input": "required name=search_term_string",
      },
    }),
    organization: () => ({
      "@context": "https://schema.org",
      "@type": "Organization",
      name: process.env.REACT_APP_ORGANIZATION_NAME || "WaktAI",
      url: process.env.REACT_APP_SITE_URL || "https://waktai.com",
      logo: process.env.REACT_APP_ORGANIZATION_LOGO || "https://waktai.com/logo.jpg",
      description:
        "WaktAI provides AI-driven solutions, including Generative AI, Large Language Models, and Retrieval-Augmented Generation, to support sustainability in enterprises.",
      sameAs: [
        "https://www.facebook.com/WaktaiAI",
        "https://twitter.com/WaktaiAI",
        "https://www.linkedin.com/company/waktai",
      ],
    }),
  },
};
