import { useEffect, useState } from 'react';

import { Popover, PopoverButton, PopoverBackdrop, PopoverPanel } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Button } from '../ui/Button';
import { Container } from '../ui/Container';
import { Logo } from '../ui/Logo';
import { NavLink } from '../ui/NavLink';

function MobileNavLink({ href, children }) {
  return (
    <PopoverButton 
      as={Link} 
      to={href} 
      className="block w-full p-2" 
      aria-label={`Navigate to ${children}`}
    >
      {children}
    </PopoverButton>
  );
}

MobileNavLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={`origin-center transition ${open ? 'scale-90 opacity-0' : ''}`}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={`origin-center transition ${!open ? 'scale-90 opacity-0' : ''}`}
      />
    </svg>
  );
}

MobileNavIcon.propTypes = {
  open: PropTypes.bool.isRequired,
};

function MobileNavigation() {
  return (
    <Popover>
      <PopoverButton
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </PopoverButton>
      <PopoverBackdrop
        transition
        className="fixed inset-0 bg-slate-300/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <PopoverPanel
        transition
        className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <MobileNavLink href="#features">Features</MobileNavLink>
        <MobileNavLink href="#testimonials">Testimonials</MobileNavLink>
        <MobileNavLink href="#pricing">Pricing</MobileNavLink>
        <hr className="m-2 border-slate-300/40" />
        <MobileNavLink href="/login">Sign in</MobileNavLink>
      </PopoverPanel>
    </Popover>
  );
}

const mainNavItems = [
  { name: 'Features', href: '#features' },
  { name: 'Testimonials', href: '#testimonials' },
  { name: 'Pricing', href: '#pricing' }
];

export function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Generate navigation schema
  const navigationSchema = {
    '@context': 'https://schema.org',
    '@type': 'SiteNavigationElement',
    'name': 'Main Navigation',
    'hasPart': mainNavItems.map(item => ({
      '@type': 'WebPage',
      'name': item.name,
      'url': `https://waktai.ai${item.href}`
    }))
  };

  // Generate organization schema
  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': 'Waktai',
    'logo': 'https://waktai.ai/logo.png',
    'url': 'https://waktai.ai',
    'sameAs': [
      'https://twitter.com/waktai',
      'https://linkedin.com/company/waktai',
      'https://github.com/waktai'
    ]
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(navigationSchema)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>
      </Helmet>

      <header
        className={`sticky top-0 z-50 transition-all duration-300 ${
          isScrolled ? 'bg-white shadow-lg py-2' : 'bg-white py-2'
        }`}
        role="banner"
      >
        <Container>
          <nav 
            className="relative z-50 flex justify-between" 
            aria-label="Main Navigation"
            role="navigation"
          >
            <div className="flex items-center md:gap-x-12">
              <Link to="/" aria-label="Waktai Home">
                <Logo className="h-10 w-auto" alt="Waktai - AI-powered Solutions" />
              </Link>
              <div className="hidden md:flex md:gap-x-6">
                {mainNavItems.map(item => (
                  <NavLink 
                    key={item.href}
                    href={item.href} 
                    aria-label={`View ${item.name.toLowerCase()}`}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
            <div className="flex items-center gap-x-5 md:gap-x-8">
              <div className="hidden md:block">
                <NavLink 
                  href="/login" 
                  aria-label="Sign in to your account"
                >
                  Sign in
                </NavLink>
              </div>
              <Button 
                href="/register" 
                color="blue" 
                aria-label="Get started with Waktai today"
              >
                <span>
                  Get started <span className="hidden lg:inline">today</span>
                </span>
              </Button>
              <div className="-mr-1 md:hidden">
                <MobileNavigation />
              </div>
            </div>
          </nav>
        </Container>
      </header>
    </>
  );
}