import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';


export function NavLink({ href, children, activeClassName = 'text-blue-600', className, exact = false }) {
  const { pathname } = useLocation();
  const isActive = exact ? pathname === href : pathname.startsWith(href);

  return (
    <Link
      to={href}
      className={clsx(
        'inline-block px-3 py-2 text-sm font-medium transition-colors hover:bg-blue-50',
        isActive ? activeClassName : 'text-gray-700',
        className
      )}
    >
      {children}
    </Link>
  );
}

NavLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  exact: PropTypes.bool,
};
