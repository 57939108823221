import React, { Suspense } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from "react-helmet-async";
import { Routes, Route } from 'react-router-dom';

import ErrorBoundary from './components/common/ErrorBoundary';
import { Footer } from './components/common/Footer';
import { Header } from './components/common/Header';
import { SEO } from './components/common/Seo';
import { seoConfig } from './config/seoConfig';

// Pages - Using lazy loading for better performance
const LandingPage = React.lazy(() => import('./pages/LandingPage'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));

// Initialize Query Client
const queryClient = new QueryClient();

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          {/* Default SEO that will be overridden by page-specific SEO */}
          <SEO 
            title={seoConfig.defaults.title}
            description={seoConfig.defaults.description}
            schema={seoConfig.getSchemas.organization()}
          />
          <Header />
          <main>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/about" element={<NotFoundPage />} />
              </Routes>
            </Suspense>
          </main>
          <Footer />
        </ErrorBoundary>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;