import React from 'react';

import PropTypes from 'prop-types'; // Import PropTypes for validation
import { Helmet } from 'react-helmet-async'; // Import Helmet from react-helmet-async

import { seoConfig } from '../../config/seoConfig';

// Correcting to export function SEO as per your convention
export function SEO({ title, description, keywords, ogImage }) {
  const {
    siteName,
    siteUrl,
    defaults: defaultSeo,
    // Removed logoUrl since it is unused in the component
  } = seoConfig;

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title || defaultSeo.title}</title>
      <meta
        name="description"
        content={description || defaultSeo.description}
      />
      <meta
        name="keywords"
        content={keywords || defaultSeo.keywords}
      />
      <meta name="author" content={siteName} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Canonical Link */}
      <link rel="canonical" href={siteUrl} />

      {/* Favicon and Apple Touch Icon */}
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={title || defaultSeo.title} />
      <meta
        property="og:description"
        content={description || defaultSeo.description}
      />
      <meta property="og:image" content={ogImage || defaultSeo.ogImage} />
      <meta property="og:site_name" content={siteName} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={siteUrl} />
      <meta
        property="twitter:title"
        content={title || defaultSeo.title}
      />
      <meta
        property="twitter:description"
        content={description || defaultSeo.description}
      />
      <meta property="twitter:image" content={ogImage || defaultSeo.ogImage} />
      
      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(seoConfig.getSchemas.organization())}
      </script>
    </Helmet>
  );
}

// Define PropTypes for runtime validation
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  ogImage: PropTypes.string,
};

// Default props in case values are not provided
SEO.defaultProps = {
  title: seoConfig.defaults.title,
  description: seoConfig.defaults.description,
  keywords: seoConfig.defaults.keywords,
  ogImage: seoConfig.defaults.ogImage,
};
